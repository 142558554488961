

/* $family: 'NeueFreutiger World', Arial, sans-serif; */

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 100;
    src: url('assets/fonts/NeueFrutigerWorld-Thin.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 100;
    font-style: italic;
    src: url('assets/fonts/NeueFrutigerWorld-ThinIt.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 200;
    src: url('assets/fonts/NeueFrutigerWorld-UltLt.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 200;
    font-style: italic;
    src: url('assets/fonts/NeueFrutigerWorld-UltLtIt.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 300;
    src: url('assets/fonts/NeueFrutigerWorld-Light.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 300;
    font-style: italic;
    src: url('assets/fonts/NeueFrutigerWorld-LightIt.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 400;
    src: url('assets/fonts/NeueFrutigerWorld-Regular.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 500;
    src: url('assets/fonts/NeueFrutigerWorld-Medium.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 500;
    font-style: italic;
    src: url('assets/fonts/NeueFrutigerWorld-MediumIt.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 700;
    src: url('assets/fonts/NeueFrutigerWorld-Bold.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 700;
    font-style: italic;
    src: url('assets/fonts/NeueFrutigerWorld-BoldIt.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 800;
    src: url('assets/fonts/NeueFrutigerWorld-Heavy.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 800;
    font-style: italic;
    src: url('assets/fonts/NeueFrutigerWorld-HeavyIt.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 900;
    src: url('assets/fonts/NeueFrutigerWorld-Black.ttf') format("truetype");
}

@font-face {
    font-family: 'NeueFreutiger World';
    font-weight: 900;
    font-style: italic;
    src: url('assets/fonts/NeueFrutigerWorld-BlackIt.ttf') format("truetype");
}